import React from 'react';
import ReactDOM from 'react-dom';
import 'react-image-lightbox/style.css';
import 'bulma/css/bulma.css';
import './App.css';
import './index.css';


// import App from './App';

import SimpleReactLightbox from 'simple-react-lightbox';
import { App } from './App';


ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
);
