import './header.css';

export function Header() {
  return (
    <header className="header">

      <div className="image-container">
        <img src="/images/header.jpg" className="cover" />
      </div>
      {/* <h1>Mona &amp; Flo</h1> */}
      <h1>Danke!</h1>
      <span className="scrollLabel">Scroll</span>
      <span className="scrolldown"></span>
    </header>
  );
}
