import logo from './logo.svg';
import { Header } from './components/header/Header';
import { Section } from './components/section/Section';
import { Thanks } from './components/view/Thanks';
import { Footer } from './components/view/Footer';
import { Gallery } from './components/gallery/Gallery';
import { Supporters } from './components/view/Supports';
import { Findings } from './components/view/Findings';

// window.addEventListener('scroll', (e) => { console.log(e)});

export function App() {
  return (
    <>
      <Header />
      <Thanks />
      <Findings />
      <Supporters />
      <Gallery />
      <Footer />
    </>
  );
}
