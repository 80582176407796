export function Footer() {
    return (
        <footer className="footer" style={{backgroundColor: 'var(--footer-green)'}}>
            <div className="content has-text-centered">
            <p>A <b>♥ Flowmotion</b> production</p>
            <p><a href="mailto:florian_guembel1@web.de" style={{
                color: 'var(--section-brighter)',
                fontWeight: 'bold',
                textTransform: 'uppercase'
            }}>Schreib uns 📧</a></p>
            </div>
        </footer>
    )
}