import LazyLoad from "react-lazyload";
import { Section } from "../section/Section";
import './gallery.css';

import { useState } from "react";
import Lightbox from "react-image-lightbox";

const RANGE = 297;
const PATH_PREFIX = '/images/';

const GALLERY_OPTIONS = {
    buttons: {
        
    }
}

const GALLERY_ELEMENTS = new Array(RANGE).fill(0).map((item, index) => {
    const fileName = `hochzeit-${String(index + 1).padStart(4, '0')}`;

    return {
        fileName,
        src: `${PATH_PREFIX}${fileName}.JPG`,
        thumbnail: `${PATH_PREFIX}thumbs/${fileName}.JPG`
    };
});

const GALLERY_SRC = GALLERY_ELEMENTS.map(({ src }) => src );

export function Gallery() {

    const [ isLightboxVisible, setVisibility ] = useState(false);
    const [ lightboxIndex, setLightboxIndex ] = useState(0);

    const imageGalleryClickHandler = (index) => {
        setLightboxIndex(index);
        setVisibility(true);
    };


    return (
        <Section variant="bright" effect="none">
            { 
                isLightboxVisible && (
                    <Lightbox
                        mainSrc={GALLERY_SRC[lightboxIndex]}
                        nextSrc={GALLERY_SRC[(lightboxIndex + 1) % GALLERY_SRC.length]}
                        prevSrc={GALLERY_SRC[(lightboxIndex + GALLERY_SRC.length - 1) % GALLERY_SRC.length]}
                        onCloseRequest={() => setVisibility(false)}
                        onMovePrevRequest={() =>
                            setLightboxIndex((lightboxIndex + GALLERY_SRC.length - 1) % GALLERY_SRC.length)
                        }
                        onMoveNextRequest={() =>
                            setLightboxIndex((lightboxIndex + 1) % GALLERY_SRC.length)
                        }
                    />
                )
            }
            <div className="grid-container">
                {
                    GALLERY_ELEMENTS.map((item, index) => (
                        <div key={`image-${index}`} id={item.fileName} className="image-wrapper">
                            <LazyLoad>
                                <img src={item.thumbnail} onClick={(e) => { e.preventDefault(); e.stopPropagation(); imageGalleryClickHandler(index) }} />
                            </LazyLoad>
                        </div>
                    ))
                }
            </div>
        </Section>
    )
}