import { Section } from "../section/Section";

export function Thanks() {
    return (
        <Section>
            <h2 className="title is-size-1 has-text-centered">Danke!</h2>
            <p className="has-text-centered">Ihr habt dazu beigetragen, dass dieser Tag für uns unvergessen bleiben wird. Wir haben jeden Moment in vollen Zügen genossen!</p>
            <p className="has-text-centered">Von ganzem Herzen möchten wir uns für die schöne gemeinsame Zeit, die vielen Geschenke, die Glückwünsche und die netten kleinen Überraschungen bedanken!</p>
            <p className="is-size-3 has-text-centered mt-6">Mona &amp; Flo</p>
        </Section>
    )
}