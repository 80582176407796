import './section.css';
import classnames from 'classnames';
import { useRef, useState, useEffect } from 'react';

export function Section ({ children, effect = 'default', variant = 'default', classes = [], container = [], isFluid = false }) {
  
  const [isVisible, setVisible] = useState(effect === 'none');
  const [ wasVisible, setPastVisibility ] = useState(false);
  const domRef = useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting || effect === 'none');
        if (entry.isIntersecting) {
          setPastVisibility(true);
        }
      });
    }, {
      threshold: .25
    });

    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

 

  return (
    <section className={
      classnames(
        'section',
        classes,
        { [ `color-${variant}`]: true },
        // { [ style['is-visible']]: true },
      )
    } 
    ref={domRef}>
      <div className={
        classnames(
          'container',
          container,
          { ['is-visible']: isVisible || wasVisible },
          { ['is-fluid']: isFluid },
          { [`fade-in-${effect}`]: true }
        )}>
        {children}
      </div>
    </section>
  );
}